<template>
<div class="page_company_detail" v-loading="loading">
  <div class="company_top">
    <div class="content">
      <div class="company_logo">
        <img :src="companyInfo.logoUrl"/>
      </div>
      <div class="company_brief">
        <div class="company_title">{{companyInfo.fullName}}</div>
        <div class="company_salary">
          <span>{{companyInfo.industryClassificationName}}</span> | <span>{{companyInfo.companyScaleName}}</span>
        </div>
        <div class="tag_list">
          <div class="tag" :key="item.welfareName" v-for="item in companyInfo.companyWelfareList">{{item.welfareName}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom_content content">
    <div>
      <div class="company_detail">
        <div class="company_detail_inner">
          <div class="detail_title">公司简介</div>
          <div class="detail_content" v-html="companyInfo.description">

          </div>
          <div class="detail_title">公司产品</div>
          <div class="detail_content">
            {{companyInfo.mainBusiness}}
          </div>
        </div>
      </div>

      <div class="company_detail">
        <div class="company_detail_inner">
          <div class="detail_title">公司地址</div>
          <div class="detail_content">
            <div class="address">
              <img src="../assets/img/icon_location.png" class="location"/> {{companyInfo.address}}
            </div>
            <div class="map_wrapper">
<!--              <iframe src="https://uri.amap.com/marker?position=116.473195,39.993253"></iframe>-->
              <div ref="map"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="company_detail company_detail_recruit">
          <div class="detail_title">在招职位</div>
          <div class="detail_content card_list">
            <apply-job-card
                v-for="item in jobList"
                :item="item"
                :key="item.companyPostId"
            ></apply-job-card>

            <el-pagination background layout="prev, pager, next"
                           :page-size="param.pageSize"
                           v-model:current-page="param.pageNum"
                           @current-page="pageChange"
                           :total="total" />
          </div>
      </div>

    </div>



    <div class="content_right" style="margin-left:20px;">
<!--      <div class="a_wrapper">-->
<!--        <img src="../assets/img/test_ad.png"/>-->
<!--      </div>-->
<!--      <div class="a_wrapper">-->
<!--        <img src="../assets/img/test_ad.png"/>-->
<!--      </div>-->
      <right-ad></right-ad>
      <div class="company_recruit">
        <div class="company_recruit_inner">
          <div class="title">企业招聘</div>

          <div class="company_item" @click="goCompany(item)" :key="item.companyId" v-for="item in companyList">
            <div style="display:flex;align-items: center;flex:1;">
              <img :src="item.logoUrl"/>
              <div class="company_title">{{item.fullName}}</div>
            </div>
            <div class="job_count"><span>{{item.companyPostCount}}个</span>职位&gt;&gt;</div>
          </div>
<!--          <div class="company_item">-->
<!--            <div style="display:flex;align-items: center">-->
<!--              <img src="../assets/img/test_photo.svg"/>-->
<!--              <div class="company_title">万达集团</div>-->
<!--            </div>-->
<!--            <div class="job_count"><span>710个</span>职位&gt;&gt;</div>-->
<!--          </div>-->
<!--          <div class="company_item">-->
<!--            <div style="display:flex;align-items: center">-->
<!--              <img src="../assets/img/test_photo.svg"/>-->
<!--              <div class="company_title">万达集团</div>-->
<!--            </div>-->
<!--            <div class="job_count"><span>710个</span>职位&gt;&gt;</div>-->
<!--          </div>-->

          <div style="text-align: center;margin-bottom:30px;">
            <router-link to="/company_list">
              <el-button type="primary" round class="more_company">更多企业</el-button>
            </router-link>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ApplyJobCard from "@/components/applyJobCard";
import rightAd from "@/components/rightAd";
import {getCompany,inCompanyPostList,moreCompanyList} from "@/api/user/user";

export default {
  name: "companyDetail",
  components: {ApplyJobCard,rightAd},
  data(){
    return {
      loading:true,
      map:"",
      companyInfo:{
        companyWelfareList:[]
      },
      jobList:[],
      companyList:[],
      total:0,
      param:{
        companyId:"",
        pageNum:1,
        pageSize:10
      }
    }
  },
  methods:{
    goCompany(info){
      this.$router.push({
        path:"/companyDetail",
        query:{
          id:info.companyId
        }
      });
    },
    pageChange(v){
      this.param.pageNum = v;
      this.getCompanyList();
    },
    getCompanyList(){
      inCompanyPostList(this.param).then(res => {
          if(res.code === 0){
            this.total = res.data.total;
            this.jobList = res.data.rows;
          }
      });
    },
    initMap(){
      if(this.map!==""){
        return ;
      }
      var center = new TMap.LatLng(39.984120, 116.307484)
      //定义map变量，调用 TMap.Map() 构造函数创建地图

      this.map = new TMap.Map(this.$refs.map, {
        center: center,//设置地图中心点坐标
        zoom: 17.2,   //设置地图缩放级别
        pitch: 43.5,  //设置俯仰角
        rotation: 45    //设置地图旋转角度
      });
      new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            // 标记位置(纬度，经度，高度)
            position: center,
            id: 'marker',
          },
        ],
      });
    },
    getCompany(){
      this.loading = true;
      getCompany({
        companyId:this.$route.query.id
      }).then(res => {
        if(res.code === 0){
          this.companyInfo = res.data
          this.initMap();
        }
        this.loading = false;
      })
      this.param.companyId = this.$route.query.id;
      this.getCompanyList();
      moreCompanyList({
        companyId:this.$route.query.id,
        pageNum:1,
        pageSize:10
      }).then(res => {
        if(res.code === 0){
          this.companyList = res.data.rows;
        }
      });
    },
  },
  watch:{
    "$route.query.id":{
      handler(v){
        if(v){
          this.getCompany();
        }
      }
    }
  },
  mounted(){
    document.title="公司详情"
    if(this.$route.query.id){
      this.getCompany()
    }else{
      this.$router.push("/company");
    }
  }
}
</script>

<style lang="less">
@import "../assets/css/variable";
  .page_company_detail{
    .company_recruit{
      background-color:#fff;
      overflow: hidden;
      margin-top:10px;
    }
    .company_recruit_inner{
      margin:25px 20px 20px;
      .title{
        font-size: 20px;
        color: #00b0b1;
        margin: 0 auto 20px;
        text-align: center;
      }
      .company_item{
        display:flex;
        justify-content: space-between;
        margin-bottom:20px;
        cursor:pointer;
        img{
          width:40px;
          height:40px;
          margin-right:5px;
        }
        .company_title{
          font-size: 18px;
          color: #1a1a1a;
        }
        .job_count{
          font-size: 16px;
          display: flex;
          align-items: center;
          width:85px;
          span{
            color:@blue_color;
          }
        }
        .more_company{
          margin:0 auto;
          width:160px;
          height:45px;
          padding:0;

        }
      }
    }
    .bottom_content{
      display:flex;
      justify-content: space-between;
      margin-top:20px;
      .company_detail_recruit{
        background-color:transparent;
        .el-pagination{
          display:flex;
          justify-content: center;
          margin-top:35px;
        }
      }
      .card_list{
        .apply_job_card{
          margin-bottom:15px;
        }
      }
      //.a_wrapper:first-child{
      //  margin-top:0;
      //}
      .address{
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
      }
      .location{
        width:13px;
        margin-right:5px;
      }
    }
    .map_wrapper{
      margin-top:10px;
      iframe{
        width:784px;
        height:400px;
        border:none;
      }
    }
    .company_detail{
      background-color:#fff;
      overflow: hidden;
      width:862px;
    }
    .detail_title{
      font-size: 19px;
      color: #1a1a1a;
      margin:30px 0;
      display: flex;
      justify-content: space-between;
      .look_more{
        font-size: 16px;
        color: #00b0b1;
      }
    }
    .detail_content{
      font-size: 15px;
      color: #333333;
    }
    .company_detail_inner{
      margin:40px;
    }
    .company_top{
      height:220px;
      background-color:#fff;
      overflow: hidden;
      .content{
        display:flex;
        margin-top:50px;
      }
      .company_logo{
        width:80px;
        height:80px;
        img{
          width:100%;
          height:100%;
        }
      }
      .company_title{
        font-size: 28px;
        color: #1a1a1a;
        font-weight: bold;
      }
      .company_salary{
        font-size: 14px;
        color: #333333;
        margin-top:15px;
      }
      .tag_list{
        margin-top:30px;
      }
      .company_brief{
        margin-left:15px;

      }
    }
  }
</style>
