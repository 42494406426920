<template>
  <div class="apply_job_card">
    <div class="job_card_inner">
      <div class="job_detail">
        <div class="job_title">
          <span style="flex:1">{{item.postName}}</span>
          <div v-if="item.salType === 1" class="job_salary">{{item.salLow}}-{{item.salHigh}}K</div>
          <div v-else-if="item.salType === 2" class="job_salary">{{userStore.SALARY_TALK}}</div>
          <div v-else-if="item.salType === 3" class="job_salary">{{item.salLow}}-{{item.salHigh}}/天</div>
        </div>
        <div class="job_brief">
          <span>{{item.cityName}}</span> | <span>{{item.workExperienceName}}</span> | <span>{{userStore.getDegree(item.education)}}</span>
        </div>
        <div class="tag_list">
          <div class="tag" :key="ele" v-for="ele in keywords">{{ele}}</div>
        </div>
      </div>

      <div class="company_wrapper">
        <el-button v-if="userStore.isLogin" type="primary" round @click="sendResume">投递简历</el-button>
        <el-button round @click="goUrl('detail')">查看详情</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/store/user/user";
import {applyJob} from "@/api/user/user";
import {keywordSplit} from "@/util/util";

export default {
  name: "applyJobCard",
  props:["item"],
  data(){
    return {
      keywords:[]
    }
  },
  methods:{
    goUrl(act){
      if(act === "detail"){
        this.$router.push({
          path:"/jobDetail",
          query:{
            id:this.item.companyPostId
          }
        });
      }
    },
    sendResume(){
      if(!this.userStore.isLogin){
         this.$message({
           type:"error",
           message:"您尚未登录"
         });
         return;
      }
      applyJob({
        companyPostId:this.item.companyPostId
      }).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"简历已投递"
          });
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    }
  },
  mounted(){
    if(this.item.keyWords){
      this.keywords = keywordSplit(this.item.keyWords);
    }
  },
  computed:{
    ...mapStores(useUserStore)
  },
}
</script>

<style scoped lang="less">
@import "../assets/css/variable";
.apply_job_card{
  width:862px;
  height:164px;
  overflow: hidden;
  background-color:#fff;
  .company_wrapper{
    display:flex;
    align-items: center;
    justify-content: space-around;
  }
  .job_card_inner{
    margin:25px;
    position:relative;
    display:flex;
    justify-content: space-between;
  }
  .job_title{
    font-size: 18px;
    color: #1a1a1a;
    display:flex;
    align-items: flex-start;
    width:400px;
    justify-content: space-between;
    .job_salary{
      color:#ed6e2d;
    }
  }
  .job_brief{
    font-size: 14px;
    color: #999999;
    margin-top:15px;
  }
  .el-button.is-round{
    width:120px;
    height:45px;
    border-radius:25px;
    font-size:16px;
    padding:0;
    text-align: center;
  }
}
</style>
